//import React from 'react'
import { Container } from "react-bootstrap";
import { Outlet } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";

import HeaderLeft from "./components/HeaderLeft";
import Footer from "./components/Footer";

import bgImage from "./assets/zpotterGymBG.jpg";
import { useEffect, useState } from "react";
import HeaderTop from "./components/HeaderTop";
import MobileBottomMenuScreen from "./screens/MobileBottomMenuScreen";

const App = () => {
  // ENABLE THIS CODE FOR AUTO LOGOFF
  const { userInfo } = useSelector((state) => state.auth);
  // const dispatch = useDispatch();
  // setTimeout(() => {
  //   console.log("currTime - loginTime");
  //   console.log(
  //     new Date(getCurrentDate() + getCurrentTime()) -
  //       new Date(userInfo?.loginDate)
  //   );

  //   if (
  //     userInfo !== null &&
  //     (new Date(getCurrentDate() + getCurrentTime()) -
  //       new Date(userInfo?.loginDate)) /
  //       1000 >
  //       86400
  //   ) {
  //     console.log("clearing redux after 60 minutes");
  //     dispatch(logout());
  //   }
  // }, 60000);

  const [mobileScreen, setMobileScreen] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setMobileScreen(window.innerWidth < 600);
      // console.log("Mobile Screen");
      // console.log(window.innerWidth);
      // console.log(window.innerWidth < 600);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [expandMenu, setExpandMenu] = useState(true);

  return (
    <>
      <ToastContainer position="top-center" autoClose="3000" />
      {mobileScreen || !userInfo ? (
        <HeaderTop></HeaderTop>
      ) : (
        <HeaderLeft leftMenuOpen={expandMenu} setExpandMenu={setExpandMenu} />
      )}

      <main
        className="py-3"
        style={{
          marginLeft:
            mobileScreen || !userInfo ? "0px" : expandMenu ? "270px" : "45px",
          height: "100vh",
          backgroundAttachment: "fixed",
          overflow: "scroll",
          backgroundColor: "#ecf3f2",
          backgroundImage: `url(${bgImage})`, // Corrected: Use backticks and template literal to reference the variable
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          marginBottom: "5rem",
        }}
      >
        <Container>
          <Outlet />
        </Container>
      </main>
      {mobileScreen && <MobileBottomMenuScreen />}
      {!mobileScreen && <Footer />}
    </>
  );
};

export default App;
